import { useState } from 'react';

// @mui
import { Stack, IconButton, InputAdornment, TextField, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { adminLogin } from '../../../services/api/auth';
import { showSnackbar } from '../../../components/Notification/Snackbar';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = () => {
    adminLogin({email, password}).then((data)=> {
      if(data.success) {  
        window.location.href = '/dashboard';
        showSnackbar().success(data.message)
       } else {
        showSnackbar().failure(data.message)

       }

    });
  };

  return (
    <>
      <Stack className='login-form' spacing={3}>
        <TextField type='email' onChange={(e) => setEmail(e.target.value)} name="email" label="Email" />

        <TextField
          name="password"
          label="Password"
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Divider />
      </Stack>

      <LoadingButton
        fullWidth
        disabled={!email || !password}
        size="large"
        type="submit"
        variant="contained"
        onClick={onSubmit}>
        Log In
      </LoadingButton>
    </>
  );
}
