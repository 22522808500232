import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
// import { translateThis } from '../../helpers/language.helper';

import AppTable from '../../components/table/index';
import ApiCaller from '../../services/api/general';
import { showSnackbar } from '../../components/Notification/Snackbar';
import SVGIcons from '../../assets/images/icons/svgIcons';
// import '../index.scss';
import './index.scss';
import AppPopOverMenu from '../../components/AppPopOverMenu/index';




const UserList = ({ type = '' }) => {
    const navigate = useNavigate();
    const [isRefreshData, setIsRefreshData] = useState(false)

    /**
   * status update API
   * @param {string} id 
   * @param {string} status 
   */
    const toggleActiveStatus = (id, status) => {
        status = status === "active" ? "inactive" : "active";
        const statusMsg = status === "active" ? "accepted" : "rejected";
        if (id) {
            const apiCaller = new ApiCaller('users');
            apiCaller.customPatchData(id, { status })
                .then(() => showSnackbar().success(`The selected user has been ${statusMsg} successfully.`))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setIsRefreshData(true);
                })
        }

    }

    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'firstName',
            headerName: 'First name',
            width: 160,
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            width: 160,
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1.5,
            width: 250,
            minWidth: 250,

        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 1,
            width: 170,
            minWidth: 170,
            renderCell: ({ row }) => <span style={{ textTransform: 'capitalize' }}>{row.role?.roleName}</span>

        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            width: 110,
            minWidth: 110,
            maxWidth: 110,
            sortable: false,
            renderCell: ({ row }) => <>
                <span style={{ display: 'flex', gap: '2px' }}><IconButton onClick={() => toggleActiveStatus(row.id, row?.status)} children={row.status === 'active' ? <SVGIcons.StatusActiveIcon /> : <SVGIcons.StatusInactiveIcon />} /></span>
            </>

        }


    ]

    /**
     * Used to build the data source url to fetch data from
     * @returns string from where the table data is loaded
     */
    const getDataURL = () => `users?type=${type}`;

    /**
     * 
     * @param {Object} row 
     * @returns Array of extra columns needed
     */
    const getExtraActionColumns = (row) => [
        {
            label: `View`,
            onClick: () => navigate(`/users/${row.id}`)
        },
        {
            label: row?.status === "active" ? "Reject" : "Accept",
            onClick: () => {
                toggleActiveStatus(row?.id, row?.status)
            }
        }
    ];

    const renderMobileRow = (row) =>
    // const boo = 'boo';
    (
        <div className='flex-main-wrp'>
            <div className='each-sec-box' style={{ width: '50px' }}>
                <div className='status-with-btn'>
                    {row.status === 'active' ? <SVGIcons.StatusActiveIcon /> : <SVGIcons.StatusInactiveIcon />}
                </div>
            </div>
            <div className='each-sec-box' style={{ width: 'calc(100% - 100px)' }}>
                <h4>{row?.firstName} {row?.lastName}</h4>
                <span style={{ display: 'block' }}>{row?.email} </span>
                <span style={{ display: 'block' }}>{row.role?.roleName}</span>
            </div>
            <div className='each-sec-box' style={{ width: '50px', padding: '0' }}>
                <div className='status-with-btn relative-btn'>
                    <AppPopOverMenu
                        icon={SVGIcons.DotMenuIcon()}
                        preventClose
                        options={[
                            {
                                label: row?.status === "active" ? "Reject" : "Accept",
                                onClick: () => {
                                    toggleActiveStatus(row?.id, row?.status)
                                }
                            },
                            // {
                            //     label: `${translateThis('Delete')}`,
                            //     onClick: () => {
                            //         onDeleteClick(row)
                            //     }
                            // }
                        ]} />
                </div>
            </div>
            <button type='button' className='full-li-btn' onClick={() => navigate(`view/${row.id}`)} />
        </div>

    )



    return (
        <>
            <div className='panel-tabel-sec f-wrp'>
                <AppTable
                    columns={tableColumns}
                    dataURL={getDataURL()}
                    isRefreshData={isRefreshData}
                    onRefreshSuccess={() => setIsRefreshData(false)}
                    isEditActionEnabled={false}
                    extraActions={getExtraActionColumns}
                    mobileRowRender={renderMobileRow}

                />

            </div>

        </>

    )
}

UserList.propTypes = {
    type: PropTypes.any,
}
export default UserList;