import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
    Card,
    Avatar,
    Stack,
    Button,
    Container,
    Typography,
    IconButton,
} from '@mui/material';
import dayjs from 'dayjs';

// Components
import AppTable from '../../components/table/index';
import ApiCaller from '../../services/api/general';
import { showSnackbar } from '../../components/Notification/Snackbar';
import { DATE_READABLE_FORMAT } from '../../constant';
import AppPopOverMenu from '../../components/AppPopOverMenu/index';
import Iconify from '../../components/iconify';
import innerBanner from '../../assets/images/Imgbanner.png';
import SVGIcons from '../../assets/images/icons/svgIcons';
import S3ImageViewerWithFallback from '../../components/awsS3ImageViewer/ImgViewerWithFallback';



const NewsList = () => {
    const navigate = useNavigate();
    const [isRefreshData, setIsRefreshData] = useState(false);


    /**
  * status update API
  * @param {string} id 
  * @param {string} status 
  */
    const toggleActiveStatus = (id, status) => {
        status = status === "draft" ? "published" : "draft";
        const statusMsg = status === "published" ? "published" : "drafted";
        if (id) {
            const apiCaller = new ApiCaller('news');
            apiCaller.customPatchData(id, { status })
                .then(() => showSnackbar().success(`The selected blog has been ${statusMsg} successfully.`))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setIsRefreshData(true);
                })
        }

    }



    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => {
                const { title } = params.row;

                return <Stack direction="row" align="center" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Avatar className='propertyImg' alt={title}>
                        <S3ImageViewerWithFallback fileKey={params.row?.image} />
                    </Avatar>
                    <Typography variant="subtitle2" align="center" style={{ fontWeight: 'normal' }} noWrap>
                        {title}
                    </Typography>
                </Stack>
            }
        },
        {
            field: 'subTitle',
            headerName: 'Subtitle',
            minWidth: 90,
            flex: 0.7,
        },
        {
            field: 'author',
            headerName: 'Author',
            flex: 0.9,
            minWidth: 180,
        },
        {
            field: 'status',
            headerName: 'Status',
            headerAlign: 'center',
            align: 'center',
            flex: 0.5,
            minWidth: 90,
            maxWidth: 90,
            sortable: false,
            renderCell: (params) => {
                const { status, id } = params.row;
                return <span style={{ display: 'flex', gap: '2px' }}><IconButton onClick={() => toggleActiveStatus(id, status)} children={status === 'published' ? <SVGIcons.StatusActiveIcon /> : <SVGIcons.StatusInactiveIcon />} /></span>
            }
        }

    ];

    /**
     * Used to build the data source url to fetch data from
     * @returns string from where the table data is loaded
     */
    const getDataURL = () => `news?`;


    /**
     * 
     * @param {Object} row 
     * @returns Array of extra columns needed
     */
    const getExtraActionColumns = (row) => [
        {
            label: `View`,
            onClick: () => navigate(`/blog/view/${row.id}`)
        },
        {
            label: row?.status === "published" ? "Mark as draft" : "Publish",
            onClick: () => {
                toggleActiveStatus(row?.id, row?.status)
            }
        }
    ]

    const renderMobileRow = (row) =>
    // const boo = 'boo';
    (
        <div className='flex-main-wrp'>
            <div className='each-sec-box' style={{ width: '50px' }}>
                <div className='status-with-btn'>
                    {row.status === 'published' ? <SVGIcons.StatusActiveIcon /> : <SVGIcons.StatusInactiveIcon />}
                </div>
            </div>
            <div className='each-sec-box' style={{ width: 'calc(100% - 100px)' }}>
                <h4 style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{row?.title}</h4>
                <span style={{ display: 'block' }}>{row?.author}</span>
                <span style={{ display: 'block' }}>{dayjs(row.createdAt).format(DATE_READABLE_FORMAT)}</span>
            </div>
            <div className='each-sec-box' style={{ width: '50px', padding: '0' }}>
                <div className='status-with-btn relative-btn'>
                    <AppPopOverMenu
                        icon={SVGIcons.DotMenuIcon()}
                        preventClose
                        options={[
                            {
                                label: `Edit`,
                                onClick: () => { navigate(`/blog/edit/${row.id}`) }
                            },
                            {
                                label: row?.status === "published" ? "Mark as draft" : "Publish",
                                onClick: () => {
                                    toggleActiveStatus(row?.id, row?.status)
                                }
                            },
                            // {
                            //     label: `${translateThis('Delete')}`,
                            //     onClick: () => {
                            //         onDeleteClick(row)
                            //     }
                            // }
                        ]} />
                </div>
            </div>
            <button type='button' className='full-li-btn' onClick={() => navigate(`/blog/view/${row.id}`)} />
        </div>

    )

    return (
        <>
            <div className='banner-wrp' style={{display: 'none'}}>
                <img src={innerBanner} alt='banner' />
            </div>
            <Container style={{ paddingTop: '30px' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>&nbsp;</Typography>
                    <Button onClick={() => navigate('add')} variant="contained" className='blu-btn' startIcon={<Iconify icon="eva:plus-fill" />}>
                        New blog
                    </Button>
                </Stack>
                <Card className='table-request'>
                    <div className='panel-tabel-sec f-wrp'>
                        <div className="user-main-wrp f-wrp">
                            <AppTable
                                columns={tableColumns}
                                dataURL={getDataURL()}
                                isRefreshData={isRefreshData}
                                isEditActionEnabled={false}
                                isViewActionEnabled={false}
                                isDeleteActionEnabled={false}
                                onRefreshSuccess={() => setIsRefreshData(false)}
                                extraActions={getExtraActionColumns}
                                actionColumnSettings={{ maxWidth: 90, minWidth: 90, }}
                                mobileRowRender={renderMobileRow}
                            />
                        </div>
                    </div>
                </Card>
            </Container>
        </>
    )
}


NewsList.propTypes = {}
export default NewsList;