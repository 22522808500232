/* eslint-disable global-require */
const AppIcons = {
  
  logo: require('../Logo.png'),
  logoOpen: require('../logoOpen.png'),
  logoClose: require('../logoClose.png'),
  default: require('../default.jpg'),
  
};
export default AppIcons;
/* eslint-enable global-require */
