import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';

import SVGIcons from '../../assets/images/icons/svgIcons';
import { uploadFile } from '../../services/api/aws';
import { getUniqueId } from '../../helpers/helper.functions';

/**
 * 
 * @param {Object} props 
 * @returns JSX Element
 */

const FileUploader = (props) => {
    const fileUploaderId = getUniqueId('file-upload-input');
    const {
        onUploadStart = () => null, // Triggers when upload start
        onUploadEnd = () => null, // Triggers when upload completed
        uploadIcon = <SVGIcons.UploadIcon />, // Upload icon
        uploadCaption = 'Upload file', // Caption for upload
        uploadInfo = '', // Shows a info string below uploader icon
        acceptOnly = '*', // Accept only the mentioned file types
        defaultImage = {},
        showPreview = false
    } = props;
    const [image, setImage] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (defaultImage.fileKey) {
            setImage(defaultImage)
        }
    }, [defaultImage]);

    /**
       * Handles the file upload
       * @param {any} e 
       */
    const handleUpload = async (e) => {
        if (image && showPreview) {
            // Temp use of image
        }
        setIsLoading(true);
        onUploadStart();
        await uploadFile(e.target.files[0], (result) => {
            if (result.success) {
                const uploadedImg = { fileKey: result.fileKey, tempURL: result.tempURL };
                setImage(uploadedImg);
                onUploadEnd(uploadedImg);
            }
            e.target.value = '';
            setIsLoading(false);
        })
    }

    return (
        <div className='img-upload-wrp f-wrp'>
            <div className='form-upload-sec f-wrp'>
                <label htmlFor={fileUploaderId} className='image-upload-label' style={{display: 'inline-block', cursor: 'pointer'}}>
                    <input
                        accept={acceptOnly}
                        className="property-image"
                        id={fileUploaderId}
                        disabled={isLoading}
                        style={{ opacity: '0', display: 'none' }}
                        type="file"
                        onChange={handleUpload}
                    />
                    {uploadIcon}
                   {uploadCaption && <p>{uploadCaption}</p>} 
                    {uploadInfo ? <><br />
                        <p>{uploadInfo}</p></> : null}
                </label>
            </div>
        </div>
    )
};

FileUploader.propTypes = {
    onUploadStart: propTypes.func,
    onUploadEnd: propTypes.func,
    defaultImage: propTypes.any,
    uploadIcon: propTypes.any,
    uploadCaption: propTypes.string,
    uploadInfo: propTypes.string,
    acceptOnly: propTypes.string,
    showPreview: propTypes.bool
};
export default FileUploader;

