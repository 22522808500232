import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, Typography, Stack, Button, Grid, InputAdornment, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import BackArrow from '../../assets/images/BackArrow.svg';
import edit from '../../assets/images/edit.svg';
import ArrowsOut from '../../assets/images/ArrowsOut.svg';
import focus from '../../assets/images/focus.svg';

import { getProperty, updateProperty } from '../../services/api/property';
import { showSnackbar } from '../../components/Notification/Snackbar';
import RichTextEditor from '../../components/rich-text-editor/index';
// ----------------------------------------------------------------------
import AwsS3ImageUploader from '../../components/AwsS3ImageUploader';
import BasicDropdown from '../../components/dropdowns/basic.dropdown';
import { PropertyCategories } from '../../models/property.model';
import SVGIcons from '../../assets/images/icons/svgIcons';
import AppLayout from '../../layouts/app/AppLayout';
import HeaderRightUserInfo from '../../components/headerRightUserInfo';
import MapSection from '../../components/MapSection';



export default function EditListItem() {
  const params = useParams();
  const navigator = useNavigate();
  const propertyId = params?.id || '';
  const [propertyItem, setPropertyItem] = useState(null);
  const [propertyAmenities, setPropertyAmenities] = useState({});
  const [location, setLocation] = useState({
      latitude: '',
      longitude: '',
      location: ''
    });


  useEffect(() => {
    if (propertyId) {
      getPropertyAs();
    }
    // eslint-disable-next-line
  }, []);

  /**
  Sets the value of the property item
  */
  const setAmenities = (name, value) => {
    const newProp = { ...propertyAmenities };
    newProp[name] = value;
    setPropertyAmenities({ ...newProp });
  }

  /**
   * Get the property by its id;
   */
  const getPropertyAs = () => {
    getProperty(propertyId).then((property) => {
      setPropertyAmenities({ ...property?.amenities || {} });
      setPropertyItem({ ...property });
      setLocation({ 
        location: property?.location,
        latitude: property?.latitude || 0,
        longitude: property?.longitude || 0,
       });
    })

  }

  /**
  Sets the value of the property item
  */
  const setValue = (name, value) => {
    const newProp = { ...propertyItem };
    newProp[name] = value;
    setPropertyItem({ ...newProp });
  }

  /**
   * Handles the form submit
   */
  const handleSubmit = () => {
    if (propertyItem.images.length > 0 && propertyItem.title && propertyItem.subTitle && propertyItem.price && propertyItem.plot && propertyItem.description && propertyItem.agentName && propertyItem.location) {
      const postData = {
        images: propertyItem.images.map((img) => img.fileKey),
        thumbnails: propertyItem?.thumbnails.filter((i) => i && i.tempURL).map((img) => img.fileKey) || [],
        title: propertyItem.title,
        subTitle: propertyItem.subTitle,
        price: propertyItem.price,
        plot: propertyItem.plot,
        description: propertyItem.description,
        location: propertyItem.location,
        features: propertyItem.features,
        bua: propertyItem.bua,
        agentName: propertyItem.agentName,
        referent: propertyItem.agentName,
        isDiscountedPrice: propertyItem.isDiscountedPrice,
        isNewListing: propertyItem.isNewListing,
        operationType: propertyItem.operationType,
        completionStatus: propertyItem.completionStatus,
        areaMeasureUnit: propertyItem.areaMeasureUnit,
        area: propertyItem.area,
        noOfBedrooms: propertyItem.noOfBedrooms,
        noOfBathrooms: propertyItem.noOfBathrooms,
        category: propertyItem.category,
        subcategory: propertyItem.subcategory,
        amenities: propertyAmenities,
      }
      updateProperty(propertyId, postData).then(() => {
        showSnackbar({}, () => navigator('/dashboard/app')).success('Property updated successfully')
      })

    } else {
      showSnackbar().warning('Please fill all required fields');
    }
  }

  const pageName = "Listing";

  return (


    <AppLayout
      pageName={pageName}
    >
      <div className="main-content-wrapper f-wrp">
        <div className='panel-main-header f-wrp'>
          <h1>{'All Listings'}</h1>
          <HeaderRightUserInfo />
        </div>
        <div className="main-container-wrp">

          <Container style={{ paddingTop: '30px' }}>

            <div className='list-main-wrp f-wrp' style={{ paddingTop: '10px' }}>
              <div className="breadcrumb f-wrp">
                <Link to={"/dashboard/app"}><img src={BackArrow} alt="back-arrow" />back</Link>
              </div>
              <div className='title-head f-wrp'>
                <div className='title-sec'>
                  <Typography variant="h4" gutterBottom>Edit listing</Typography>
                </div>
                <div className='button-sec'>
                  <Button onClick={() => navigator('/dashboard/app')} className='cancel'>Cancel</Button> &nbsp;
                  <Button onClick={handleSubmit} className='fill-btn'>Save changes</Button>
                </div>
              </div>

              {(propertyItem) && <><h3>Images</h3><AwsS3ImageUploader
                key={'uploader-img'}
                uploaderKey={'uploader-img'}
                defaultImages={propertyItem?.images || []}
                onUploadEnd={(images) => setValue("images", images)}
                onRemoveFile={(images) => setValue("images", images)}
              /></>}


              {(propertyItem) && <div className='info-sec-wrp f-wrp'>
                <div className='general-info-sec f-wrp'>
                  <div className='info-head f-wrp'>
                    <span>General info:</span>
                    <Button className='edit-btn'><img src={edit} alt="edit" /></Button>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField type='text'
                          defaultValue={propertyItem.title}
                          name="title"
                          label="Title:"
                          onChange={({ target: { name, value } }) => setValue(name, value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="Subtitle"
                          label="Subtitle:"
                          name='subTitle'
                          defaultValue={propertyItem.subTitle}
                          onChange={({ target: { name, value } }) => setValue(name, value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="price"
                          label="price"
                          name='price'
                          defaultValue={propertyItem.price}
                          onChange={({ target: { name, value } }) => setValue(name, value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                AED
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="Plotsft"
                          label="Plot sft:"
                          name='plot'
                          defaultValue={propertyItem.plot}
                          onChange={({ target: { name, value } }) => setValue(name, value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={ArrowsOut} alt="back-arrow" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="BUAsft"
                          label="BUA sft:"
                          name='bua'
                          defaultValue={propertyItem.bua}
                          onChange={({ target: { name, value } }) => setValue(name, value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={focus} alt="back-arrow" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Grid>


                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <BasicDropdown
                          key={'operationType'}
                          label="Choose operation"
                          id='selectedOperation'
                          options={[
                            {
                              'label': 'Sell',
                              "id": 'sell',
                              "value": 'sell',
                            },
                            {
                              'label': 'Rent',
                              "id": 'rent',
                              "value": 'rent',
                            },
                            {
                              'label': 'Other',
                              "id": 'other',
                              "value": 'other',
                            },

                          ]}
                          onChange={(value) => setValue('operationType', value)}
                          value={propertyItem?.operationType}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <BasicDropdown
                          key={'completionStatus'}
                          label="Completion status"
                          id='completionStatus'
                          options={[
                            {
                              'label': 'Completed',
                              "id": 'completed',
                              "value": 'completed',
                            },
                            {
                              'label': 'In progress',
                              "id": 'inprogress',
                              "value": 'inprogress',
                            },
                            {
                              'label': 'Proposed',
                              "id": 'proposed',
                              "value": 'proposed',
                            },

                          ]}
                          onChange={(value) => setValue('completionStatus', value)}
                          value={propertyItem?.completionStatus}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <BasicDropdown
                          key={'category'}
                          label="Property Type"
                          id='propertyType'
                          options={PropertyCategories}
                          onChange={(value) => setValue('category', value)}
                          value={propertyItem?.category}
                        />
                      </Stack>
                    </Grid>
                    {propertyItem?.category !== '' ? <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <BasicDropdown
                          key={'subcategory'}
                          label="Property sub type"
                          id='propertyType'
                          options={PropertyCategories.find((c) => c.id === propertyItem.category)?.subcategories}
                          onChange={(value) => setValue('subcategory', value)}
                          value={propertyItem?.subcategory}
                        />
                      </Stack>
                    </Grid> : null}

                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <BasicDropdown
                          key={'areaUnit'}
                          label="Area unit"
                          id='areaUnit'
                          options={[
                            {
                              'label': 'sqm',
                              "id": 'sqm',
                              "value": 'sqm',
                            },
                            {
                              'label': 'Sqf',
                              "id": 'sqf',
                              "value": 'sqf',
                            },

                          ]}
                          onChange={(value) => setValue('areaMeasureUnit', value)}
                          value={propertyItem?.areaMeasureUnit}
                        />
                      </Stack>
                    </Grid>

                    {(propertyItem?.areaMeasureUnit !== '') ? <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="AreaInSqmOrSqf"
                          label="Area: (in sqm or sqf)"
                          name='area'
                          defaultValue={propertyItem.area}
                          onChange={({ target: { name, value } }) => setValue(name, value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                sqm/sqf
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Grid> : null}
                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="noOfBedrooms"
                          label="Number Of Bedrooms"
                          name='noOfBedrooms'
                          defaultValue={propertyItem.noOfBedrooms}
                          onChange={({ target: { name, value } }) => setValue(name, value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SVGIcons.BedIcon style={{ width: '18px', height: '18px' }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="noOfBathrooms"
                          label="Number Of Bathrooms"
                          name='noOfBathrooms'
                          defaultValue={propertyItem.noOfBathrooms}
                          onChange={({ target: { name, value } }) => setValue(name, value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SVGIcons.BathIcon style={{ width: '18px', height: '18px' }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          type='text'
                          name="agentName"
                          title="Agent name"
                          defaultValue={propertyItem.agentName}
                          label="Agent name:"
                          onChange={({ target: { name, value } }) => setValue(name, value)}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel control={<Checkbox onChange={(e) => setValue('isDiscountedPrice', e.target.checked)} defaultChecked={propertyItem?.isDiscountedPrice} />} label="Distress Property" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setValue('isNewListing', e.target.checked)} defaultChecked={propertyItem?.isNewListing} />} label="Exclusive" />
                        </FormGroup>
                      </Stack>
                    </Grid>





                    <Grid style={{ display: 'none' }} item xs={12} sm={6} md={6}>
                      <Stack className='login-form profile-form' spacing={3}>
                        {/* <TextField
              type='text'
              name="Other"
              label="Other:"
              defaultValue="&nbsp;" /> */}
                      </Stack>
                    </Grid>
                  </Grid>
                </div>
                <div className='general-info-sec f-wrp'>
                  <div className='info-head f-wrp'>
                    <span>Description:</span>
                    <Button className='edit-btn'><img src={edit} alt="edit" /></Button>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3}>

                        <RichTextEditor
                          defaultValue={propertyItem.description}
                          onChange={(value) => setValue('description', JSON.stringify(value))} />
                        {/* <TextField
              id="Description"
              label="Description:"
              name='description'
              onChange={({ target: { name, value } }) => setValue(name, value)}
              multiline
              rows={4}
              defaultValue={propertyItem.description}
            /> */}
                      </Stack>
                    </Grid>
                  </Grid>
                </div>
                <div className='general-info-sec f-wrp'>
                  <div className='info-head f-wrp'>
                    <span>Features:</span>
                    <Button className='edit-btn'><img src={edit} alt="edit" /></Button>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <RichTextEditor defaultValue={propertyItem.features} onChange={(value) => setValue('features', JSON.stringify(value))} />
                      </Stack>
                    </Grid>
                  </Grid>
                </div>

                <div className='general-info-sec f-wrp'>
                  <div className='info-head f-wrp'>
                    <span>Amenities:</span>
                    {/* <Button className='edit-btn'><img src={edit} alt="edit" /></Button> */}
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Recreation and family</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('barbequeArea', e.target.checked)} defaultChecked={propertyItem?.amenities?.barbequeArea} />} label="Barbeque area" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('lawnOrGarden', e.target.checked)} defaultChecked={propertyItem?.amenities?.lawnOrGarden} />} label="Lawn or garden" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('cafeteria', e.target.checked)} defaultChecked={propertyItem?.amenities?.cafeteria} />} label="Cafeteria" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('daycare', e.target.checked)} defaultChecked={propertyItem?.amenities?.daycare} />} label="Daycare" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('kidsPlayArea', e.target.checked)} defaultChecked={propertyItem?.amenities?.kidsPlayArea} />} label="Kids play area" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('sundeck', e.target.checked)} defaultChecked={propertyItem?.amenities?.sundeck} />} label="Sundeck" />
                        </FormGroup>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Laundry and kitchen</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('laundryRoom', e.target.checked)} defaultChecked={propertyItem?.amenities?.laundryRoom} />} label="Laundry room" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('laundryFacility', e.target.checked)} defaultChecked={propertyItem?.amenities?.laundryFacility} />} label="Laundry facility" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('sharedKitchen', e.target.checked)} defaultChecked={propertyItem?.amenities?.sharedKitchen} />} label="Shared kitchen" />

                        </FormGroup>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Facilities</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('medicalCenter', e.target.checked)} defaultChecked={propertyItem?.amenities?.medicalCenter} />} label="Medical center" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('sauna', e.target.checked)} defaultChecked={propertyItem?.amenities?.sauna} />} label="Sauna" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('jacuzzi', e.target.checked)} defaultChecked={propertyItem?.amenities?.jacuzzi} />} label="Jacuzzi" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('disabledFriendly', e.target.checked)} defaultChecked={propertyItem?.amenities?.disabledFriendly} />} label="Disabled friendly" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('gym', e.target.checked)} defaultChecked={propertyItem?.amenities?.gym} />} label="Gym" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('healthClub', e.target.checked)} defaultChecked={propertyItem?.amenities?.healthClub} />} label="Health club" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('swimmingPool', e.target.checked)} defaultChecked={propertyItem?.amenities?.swimmingPool} />} label="Swimming pool" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('steamRoom', e.target.checked)} defaultChecked={propertyItem?.amenities?.steamRoom} />} label="Steam room" />

                        </FormGroup>
                      </Stack>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Building</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('balcony', e.target.checked)} defaultChecked={propertyItem?.amenities?.balcony} />} label="Balcony" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('terrace', e.target.checked)} defaultChecked={propertyItem?.amenities?.terrace} />} label="Terrace" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('lobby', e.target.checked)} defaultChecked={propertyItem?.amenities?.lobby} />} label="Lobby" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('serviceElevators', e.target.checked)} defaultChecked={propertyItem?.amenities?.serviceElevators} />} label="Service elevators" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('prayerRoom', e.target.checked)} defaultChecked={propertyItem?.amenities?.prayerRoom} />} label="Prayer room" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('reception', e.target.checked)} defaultChecked={propertyItem?.amenities?.reception} />} label="Reception" />
                        </FormGroup>
                      </Stack>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Miscellaneous</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('atmFacility', e.target.checked)} defaultChecked={propertyItem?.amenities?.atmFacility} />} label="ATM facility" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('concierge24h', e.target.checked)} defaultChecked={propertyItem?.amenities?.concierge24h} />} label="24h concierge" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('maidsRoom', e.target.checked)} defaultChecked={propertyItem?.amenities?.maidsRoom} />} label="Maids room" />

                        </FormGroup>
                      </Stack>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Business and security</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('businessCenter', e.target.checked)} defaultChecked={propertyItem?.amenities?.bussinessCenter} />} label="Bussiness center" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('conferenceRoom', e.target.checked)} defaultChecked={propertyItem?.amenities?.conferenceRoom} />} label="Conference room" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('securityStaff', e.target.checked)} defaultChecked={propertyItem?.amenities?.securityStaff} />} label="Security staff" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('cctvSecurity', e.target.checked)} defaultChecked={propertyItem?.amenities?.cctvSecurity} />} label="CCTV security" />

                        </FormGroup>
                      </Stack>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Features</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('doubleGlazedWindows', e.target.checked)} defaultChecked={propertyItem?.amenities?.doubleGlazedWindows} />} label="Double glazed windows" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('centrallyAirConditioned', e.target.checked)} defaultChecked={propertyItem?.amenities?.centralyAirConditioned} />} label="Centraly air-conditioned " />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('centalHeating', e.target.checked)} defaultChecked={propertyItem?.amenities?.centalHeating} />} label="Cental heating" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('electricityBackup', e.target.checked)} defaultChecked={propertyItem?.amenities?.electricityBackup} />} label="Electricity backup" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('studyRoom', e.target.checked)} defaultChecked={propertyItem?.amenities?.studyRoom} />} label="Study room" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('storageAreas', e.target.checked)} defaultChecked={propertyItem?.amenities?.storageAreas} />} label="Storage areas" />

                        </FormGroup>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Technology</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('broadbandInternet', e.target.checked)} defaultChecked={propertyItem?.amenities?.broadbandInternet} />} label="Broadband internet" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('satelliteCableTV', e.target.checked)} defaultChecked={propertyItem?.amenities?.sateliteCableTV} />} label="Satelite/Cable TV" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('intercom', e.target.checked)} defaultChecked={propertyItem?.amenities?.intercom} />} label="Intercom" />

                        </FormGroup>
                      </Stack>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Cleaning and maintenance</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('cleaningServices', e.target.checked)} defaultChecked={propertyItem?.amenities?.cleaningservices} />} label="Cleaning services" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('maintenanceStaff', e.target.checked)} defaultChecked={propertyItem?.amenities?.maintenanceStaff} />} label="Maintenance staff" />
                          <FormControlLabel control={<Checkbox onChange={(e) => setAmenities('wasteDisposal', e.target.checked)} defaultChecked={propertyItem?.amenities?.wasteDisposal} />} label="Waste disposal" />

                        </FormGroup>
                      </Stack>
                    </Grid>

                  </Grid>
                </div>


                <div className='general-info-sec f-wrp'>
                  <div className='info-head f-wrp'>
                    <span>Location</span>
                    <Button className='edit-btn'><img src={edit} alt="edit" /></Button>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3}>
                      <MapSection initialData={location} handleSelection = {(location)=>setLocation(location)} />
                      </Stack>
                    </Grid>
                  </Grid>
                </div>
              </div>}
              <div className='button-sec f-wrp'>
                <Button onClick={() => navigator('/dashboard/app')} className='cancel'>Cancel</Button> &nbsp;
                <Button onClick={handleSubmit} className='fill-btn'>Save changes</Button>
              </div>
            </div>
          </Container>

        </div>
      </div>
    </AppLayout >


  );
}
