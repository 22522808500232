import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Button, FormControlLabel, Radio, RadioGroup, Container, Typography, IconButton, Grid, Stack } from '@mui/material';

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { DATE_FORMAT, USER_ROLES } from '../../constant';
import ApiCaller from '../../services/api/general';
import { showSnackbar } from '../../components/Notification/Snackbar';
import SVGIcons from '../../assets/images/icons/svgIcons';
import { translateThis } from '../../helpers/language.helper';

import BackArrow from '../../assets/images/BackArrow.svg';
import edit from '../../assets/images/edit.svg';
import FileUploader from '../../components/AwsS3ImageUploader/file.uploader';
import AppLayout from '../../layouts/app/AppLayout';
import HeaderRightUserInfo from '../../components/headerRightUserInfo';
import S3ImageViewerWithFallback from '../../components/awsS3ImageViewer/ImgViewerWithFallback';
import ProfilePicUploader from '../../components/AwsS3ImageUploader/profilePic.uploader';



const AddEditViewUser = () => {
    const { id, type = 'view' } = useParams();
    // const navigate = useNavigate();
    const navigator = useNavigate();
    const apiCaller = new ApiCaller('users')
    const [isLoading, setIsLoading] = useState(id !== undefined);
    const [isTouched, setIsTouched] = useState(false);
    const [isModifying, setIsModifying] = useState(false);
    const [isEditEnabled, setIsEditEnabled] = useState(id === undefined || type === 'edit');
    const [data, setData] = useState([]);
    const [formData, setFormData] = React.useState({
        role: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        organization: '',
        address: '',
        profilePic: '',
        image: {
            fileKey: '',
            tempURL: ''
        },
        phoneNumber: '',
        state: '',
        city: '',
        zipCode: '',
        dateOfBirth: '',
        age: '',
        gender: '',
        notes: '',

    });

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getData()
        return () => {
            setData([])
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        if (id) {
            setIsLoading(true);
            apiCaller.getData(id)
                .then(({ data }) => {
                    if (data) {
                        setData(data);
                        setFormData({ ...data, ...data?.details ?? {}, role: data?.roleId?.roleName ?? '' });
                    }

                })
                .catch(() => showSnackbar().failure('Something went wrong'))
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false)
        }

    }

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = { ...formData };
        setFormData({ ...formDataAs, ...{ [name]: value } });
    }

    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData && formData.role && formData.firstName &&
            formData.lastName &&
            formData.email) {
            if (!id) {
                if (formData?.password) {
                    isValid = true;
                }
            } else {
                isValid = true;
            }

        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = () => {
        if (isEditEnabled) {
            setIsTouched(true);
            if (isValidForm()) {
                // Submitting data to backend for registration
                setIsModifying(true);
                const postData = {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    role: formData?.role,
                    details: {
                        organization: formData?.organization,
                        address: formData?.address,
                        phoneNumber: formData?.phoneNumber,
                        state: formData?.state,
                        city: formData?.city,
                        zipCode: formData?.zipCode,
                        dateOfBirth: formData?.dateOfBirth,
                        age: formData?.age,
                        gender: formData?.gender,
                        notes: formData?.notes,
                        // image: formData?.image?.fileKey || '',
                        image: {
                            fileKey: formData.image.fileKey || '',
                            tempURL: formData.image.tempURL || ''
                        },
                    }
                }
                let action = saveUser;
                let message = 'User created successfully!';
                if (id) {
                    action = updateUser;
                    message = 'User updated successfully!';
                }
                action(postData).then((data) => {
                    if (data && data.success) {
                        showSnackbar().success(message);
                        navigator('/users')
                    } else {
                        showSnackbar().failure(data?.message ?? "Something went wrong!");
                    }
                }).finally(() => setIsModifying(false));
            } else {
                showSnackbar().warning("Please fill all required data")
            }
        } else {
            setIsEditEnabled(true)
        }

    };

    /**
     * On uploading profile pic
     * @param {string} pic 
     */
    // const onUploadProfileImg = (pic) => {
    //     if (id) {
    //         const postData = {
    //             profilePic: pic
    //         }
    //         apiCaller.customPatchData(id, postData).then(() => null)
    //     } else {
    //         setFormValue('profilePic', pic)
    //     }
    // }

    /**
     * Updates the user data
     * @param {Object} postData 
     */
    const updateUser = async (postData) => apiCaller.customPatchData(id, postData)

    /**
     * Saves a new user
     * @param {Object} postData 
     */
    const saveUser = async (postData) => {
        postData.email = formData.email;
        postData.password = formData.password;
        if (formData?.profilePic) {
            postData.profilePic = formData.profilePic;
        }
        return apiCaller.postData(postData)
    }

    console.log(formData, 'formData');
    const pageName = "Users";

    return (
        <>
            <AppLayout
                pageName={pageName}
            >
                <div className="main-content-wrapper AddEditViewUser f-wrp">
                    <div className='panel-main-header f-wrp'>
                        <h1>{'Users'}</h1>
                        <HeaderRightUserInfo />
                    </div>
                    <div className="main-container-wrp">
                        <Container>
                            {!isLoading ?
                                <div className='list-main-wrp addEditNews addEditUser f-wrp' style={{ paddingBottom: '0', paddingTop: '40px' }}>
                                    <div className="breadcrumb f-wrp">
                                        <Link to={"/users"}><img src={BackArrow} alt="back-arrow" />back</Link>
                                    </div>
                                    <div className='title-head f-wrp'>
                                        <div className='title-sec'>
                                            <Typography variant="h4" gutterBottom>New User</Typography>
                                        </div>
                                    </div>
                                    <div className='singleImageUpload f-wrp'>
                                        <>
                                            <h3>Images</h3>
                                            <div className='image-uploader'>
                                            {isEditEnabled ? <FileUploader
                                                acceptOnly='image/*'
                                                // uploadIcon={uploadBtn2}
                                                uploadCaption={''}
                                                onUploadEnd={(file) => setFormValue('image', file)}
                                            /> : null}

                                                {formData?.image?.tempURL ? <span className='uploaded-img'>
                                                    {isEditEnabled ? <IconButton children={<SVGIcons.CloseCrossIcon />} onClick={() => setFormValue('image', { fileKey: '', tempURL: '' })} /> : null}
                                                    <img style={{ width: '200px', height: '200px' }} src={formData.image.tempURL} alt={"bulletin"} />
                                                </span> : null}
                                            </div>
                                        </>
                                    </div>

                                    <div className='info-sec-wrp f-wrp'>
                                        <div className='general-info-sec f-wrp'>
                                            <div className='info-head f-wrp'>
                                                <span>General info:</span>
                                                <Button style={{ cursor: 'default' }} className='edit-btn'><img src={edit} alt="edit" /></Button>
                                            </div>

                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <h4>{translateThis("Role")}</h4>
                                                        <RadioGroup
                                                            className='custom-radioBox'
                                                            row
                                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                                            name="controlled-radio-buttons-group"
                                                            value={formData.role}
                                                            onChange={(e) => setFormValue('role', e.target.value)}
                                                        >
                                                            <FormControlLabel value={USER_ROLES.EXTERNAL_AGENT} control={<Radio disabled={!isEditEnabled} />} label={translateThis('External Agent')} />
                                                            <FormControlLabel value={USER_ROLES.USER} control={<Radio disabled={!isEditEnabled} />} label={translateThis('Client')} />
                                                            <FormControlLabel value={USER_ROLES.ADMIN} control={<Radio disabled={!isEditEnabled} />} label={translateThis('Admin')} />
                                                        </RadioGroup>

                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <TextField
                                                            focused
                                                            disabled={!isEditEnabled}
                                                            id="firstName"
                                                            name="firstName"
                                                            label="First Name"
                                                            placeholder='Type here'
                                                            type="text"
                                                            helperText={isTouched && !formData.firstName ? 'First name is required' : ''}
                                                            error={isTouched && !formData.firstName}
                                                            value={formData?.firstName}
                                                            onChange={(e) => setFormValue('firstName', e.target.value)}

                                                        />

                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <TextField
                                                            focused
                                                            disabled={!isEditEnabled}
                                                            id="lastName"
                                                            name="Last name"
                                                            label="Last name"
                                                            placeholder='Type here'
                                                            type="text"
                                                            helperText={isTouched && !formData.lastName ? 'Last name is required' : ''}
                                                            error={isTouched && !formData.lastName}
                                                            value={formData?.lastName}
                                                            onChange={(e) => setFormValue('lastName', e.target.value)}


                                                        />

                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <TextField
                                                            focused
                                                            disabled={id !== undefined}
                                                            id="Email"
                                                            name="Email"
                                                            label="Email"
                                                            placeholder='Type here'
                                                            type="text"
                                                            value={formData?.email}
                                                            helperText={!id && isTouched && !formData.email ? 'Email is required' : ''}
                                                            error={isTouched && !formData.email}
                                                            onChange={(e) => !id ? setFormValue('email', e.target.value) : null}
                                                        />

                                                    </Stack>
                                                </Grid>
                                                {!id ? <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <TextField
                                                            focused
                                                            disabled={id !== undefined}
                                                            id="password"
                                                            name="Password"
                                                            label="Password"
                                                            placeholder='Type here'
                                                            type="text"
                                                            value={formData?.password}
                                                            helperText={isTouched && !formData.password ? 'Password is required' : 'Password should contain one uppercase one special character one number and 8 characters long '}
                                                            error={isTouched && !formData.password}
                                                            onChange={(e) => setFormValue('password', e.target.value)}
                                                        />

                                                    </Stack>
                                                </Grid> : null}


                                            </Grid>
                                        </div>

                                        <div className='general-info-sec f-wrp'>
                                            <div className='info-head f-wrp'>
                                                <span>Basic Details:</span>
                                                <Button style={{ cursor: 'default' }} className='edit-btn'><img src={edit} alt="edit" /></Button>
                                            </div>

                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <TextField
                                                            focused
                                                            disabled={!isEditEnabled}
                                                            id="organization"
                                                            name="organization"
                                                            label="Organization"
                                                            placeholder='Type here'
                                                            type="text"
                                                            value={formData?.organization}
                                                            onChange={(e) => setFormValue('organization', e.target.value)}
                                                        />

                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <TextField
                                                            focused
                                                            disabled={!isEditEnabled}
                                                            id="Address"
                                                            name="Address"
                                                            label="Address"
                                                            placeholder='Type here'
                                                            type="text"
                                                            value={formData?.address}
                                                            onChange={(e) => setFormValue('address', e.target.value)}

                                                        />

                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <TextField
                                                            focused
                                                            disabled={!isEditEnabled}
                                                            id="Phone"
                                                            name="Phone"
                                                            label="Phone"
                                                            placeholder='Type here'
                                                            type="text"
                                                            value={formData?.phoneNumber}
                                                            onChange={(e) => setFormValue('phoneNumber', e.target.value)}

                                                        />

                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <TextField
                                                            focused
                                                            disabled={!isEditEnabled}
                                                            id="State"
                                                            name="State"
                                                            label="State"
                                                            placeholder='Type here'
                                                            type="text"
                                                            value={formData?.state}
                                                            onChange={(e) => setFormValue('state', e.target.value)}
                                                        />

                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <TextField
                                                            focused
                                                            disabled={!isEditEnabled}
                                                            id="City"
                                                            name="City"
                                                            label="City"
                                                            placeholder='Type here'
                                                            type="text"
                                                            value={formData?.city}
                                                            onChange={(e) => setFormValue('city', e.target.value)}
                                                        />

                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <TextField
                                                            focused
                                                            disabled={!isEditEnabled}
                                                            id="ZipCode"
                                                            name="Zip Code"
                                                            label="Zip Code"
                                                            placeholder='Type here'
                                                            type="text"
                                                            value={formData?.zipCode}
                                                            onChange={(e) => setFormValue('zipCode', e.target.value)}

                                                        />

                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <div className='date-picker-box'>
                                                            <LocalizationProvider className="date-selector" dateAdapter={AdapterDayjs} >
                                                                <DemoContainer className="date-demo-container" components={['DatePicker']}>
                                                                    <DatePicker
                                                                        disabled={!isEditEnabled}
                                                                        // value={formData?.dateOfBirth ? dayjs(formData?.dateOfBirth) : undefined}
                                                                        defaultValue={formData?.dateOfBirth ? dayjs(formData?.dateOfBirth) : undefined}
                                                                        className='date-picker'
                                                                        slots={{ openPickerIcon: SVGIcons.CalendarIcon }}
                                                                        slotProps={{
                                                                            inputAdornment: {
                                                                                position: 'end'
                                                                            }
                                                                        }}
                                                                        onChange={(e) => {
                                                                            setFormValue('dateOfBirth', dayjs(e).format(DATE_FORMAT))
                                                                        }
                                                                        }
                                                                        label={translateThis("DOB")}
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>


                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <TextField
                                                            focused
                                                            disabled={!isEditEnabled}
                                                            id="Age"
                                                            name="Age"
                                                            label="Age (Years)"
                                                            placeholder={translateThis("Type here")}
                                                            type="text"
                                                            value={formData?.age}
                                                            onChange={(e) => setFormValue('age', e.target.value)}
                                                        />

                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <TextField
                                                            focused
                                                            disabled={!isEditEnabled}
                                                            id="Gender"
                                                            name="Gender"
                                                            label="Gender"
                                                            placeholder={translateThis("Type here")}
                                                            type="text"
                                                            value={formData?.gender}
                                                            onChange={(e) => setFormValue('gender', e.target.value)}

                                                        />

                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Stack className='login-form profile-form' spacing={3}>
                                                        <TextField
                                                            focused
                                                            disabled={!isEditEnabled}
                                                            id="notes"
                                                            name="notes"
                                                            label="Notes"
                                                            placeholder={translateThis("Type here")}
                                                            type="text"
                                                            value={formData?.notes}
                                                            onChange={(e) => setFormValue('notes', e.target.value)}

                                                        />

                                                    </Stack>
                                                </Grid>


                                            </Grid>
                                        </div>


                                        <div className='button-sec f-wrp'>
                                            {isEditEnabled ? <Button onClick={() => {
                                                if (id) {
                                                    setFormData({ ...data, ...data?.details ?? {}, role: data?.roleId?.roleName ?? '' });
                                                    setIsEditEnabled(false);
                                                } else {
                                                    navigator('/users')
                                                }

                                            }} className='cancel'>Cancel</Button> : <Button onClick={() => { navigator('/users') }} className='cancel'>Cancel</Button>}
                                            &nbsp;

                                            <Button disabled={isModifying} onClick={onSubmit} className='fill-btn'>{(id && isEditEnabled) ? translateThis('Update') : id ? translateThis('Edit') : translateThis('Save')}</Button>
                                        </div>
                                    </div>
                                </div> : <span className='page-loader'>{SVGIcons.LoaderIcon()}</span>}

                        </Container>



















                    </div>
                </div>
            </AppLayout >
        </>
    )
}

export default AddEditViewUser;