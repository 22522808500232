import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// import { translateThis } from '../../helpers/language.helper';
import './tabs.scss';


const TabLayout = (props) => {

    
    const { tabNames = [], tabContents = [], activeTab = '0' } = props;
    const [value, setValue] = React.useState(activeTab);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className='tab-sec-wrp f-wrp'>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box>
                            <TabList className='tab-header' onChange={handleChange} aria-label="tab">
                                {tabNames.map((tabObj, index)=><Tab className='tab-items' key={`tab-key-${index}`} icon={tabObj.icon} label={tabObj.name} value={`${index}`} />)}
                            </TabList>
                        </Box>
                        <Box className="tab-body f-wrp">
                        {tabContents.map((content, index)=><TabPanel key={`key-${index}`} value={`${index}`}>{content}</TabPanel>)}
                        </Box>                        
                    </TabContext>
                </Box>
            </div>

        </>
    )
}
TabLayout.propTypes = {
    activeTab: PropTypes.string,
    tabNames: PropTypes.array,
    tabContents: PropTypes.array
}
export default TabLayout;