import React from 'react';
import './header.scss';
// import Nav from '../nav-section/Nav';

// import DrawerAppBar from '../nav-section/BurgerMenu/DrawerAppBar';
// import MiniDrawer from '../nav-section/BurgerMenu/DrawerAppBarX';
import DrawerCustom from '../nav-section/BurgerMenu/customDarwer';

export default function AppHeader() {
    return (
        <div className='main-header'>
            {/* <DrawerAppBar /> */}
            {/* <MiniDrawer /> */}
            
            <DrawerCustom />
        </div>
    )

}