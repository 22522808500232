import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import AppIcons from '../../../assets/images/icons/index';
import SVGIcons from '../../../assets/images/icons/svgIcons';
import { translateThis } from '../../../helpers/language.helper';
import './DrawerApp.scss';
// import LogoutButton from '../../buttons/logout.button';
import { getAuthRole, isLoggedIn } from '../../../helpers/auth.helper';


const isUserLoggedIn = isLoggedIn();
const navItems = isUserLoggedIn ? [
  {
    title: 'All Listing',
    icon: SVGIcons.ListingIcon(),
    link: '/dashboard/app',
    access: ['admin', 'agent', 'master_admin']
  },
  {
    title: 'Inbox',
    icon: SVGIcons.InboxIcon(),
    link: '/contact-us-queries',
    access: ['admin', 'master_admin']
  },
  {
    title: 'Users',
    icon: SVGIcons.UsersIcon(),
    link: '/users',
    access: ['admin', 'master_admin']
  },
  {
    title: 'Blog',
    icon: SVGIcons.NewsIcon(),
    link: '/blog',
    access: ['admin', 'master_admin']
  },
  // {
  //   title: 'Logout',
  //   icon: SVGIcons.LogoutIcon(),
  //   link: '/logout',
  //   access: ['admin', 'agent', 'master_admin']
  // },
] : [
  {
    title: 'Login',
    icon: SVGIcons.LogoutIcon(),
    link: '/login',
    access: ['admin', 'agent', 'master_admin']
  },
];


const authMenu = isUserLoggedIn ? [
  {
    title: 'Logout',
    icon: SVGIcons.LogoutIcon(),
    link: '/login',
    access: ['admin', 'agent', 'master_admin']
  },
] : [
  {
    title: 'Login',
    icon: SVGIcons.LogoutIcon(),
    link: '/login',
    access: ['admin', 'agent', 'master_admin']
  },
];

export default function DrawerCustom() {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (open) {
    document.body.classList.add('drawer-open');
    document.body.classList.remove('drawer-close');
  } else {
    document.body.classList.remove('drawer-open');
    document.body.classList.add('drawer-close');
  }

  return (
    <>
      <div className='mob-drawer-btn'>
        {!open ? <Button className='drawer-btn' onClick={handleDrawerOpen}>
          <SVGIcons.BurgerMenuIcon />
        </Button> : <Button className='drawer-btn' onClick={handleDrawerClose}>
          <SVGIcons.BurgerMenuIcon />
        </Button>}
      </div>
      <div className='custom-navbar custom-drawer'>
        <div className='navbar-wrp'>
          <div className="logo-wrp f-wrp">
            <Link to="/">
              <span className={`BlueLogo ${open ? 'show' : 'hide'}`}><img src={AppIcons.logoOpen} alt="Logo" /></span>
              <span className={`LogoGlobe ${open ? 'hide' : 'show'}`}><img src={AppIcons.logoClose} alt="Logo" /></span>
            </Link>
            <div className='chevron-btn'>
              {open ? <button type='button' onClick={handleDrawerClose}>
                <SVGIcons.ChevronLeftIcon />
              </button> : <button type='button' onClick={handleDrawerOpen}>
                <SVGIcons.ChevronRightIcon />
              </button>}
            </div>
          </div>
          <div className={`menu-wrp`}   >
            <div className='each-nav-wrp'>
              <ul>
                {navItems.filter((nv) => nv.access.includes(getAuthRole())).map((element, key) => (
                  <li key={key}>
                    <Link 
                    // className={pathname === (element.link) ? 'active-nav' : null}
                    className={pathname.startsWith(element.link) ? 'active-nav' : null}
                    
                    to={`${element.link}`} style={{ width: '100%' }} >
                      <Button className="nav-link-btn" key={element.link} sx={{ color: '#fff' }}>
                        <span className='menu-icon'>{element.icon}</span>
                        <ListItemText primary={translateThis(element.title)} />
                      </Button>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className='each-nav-wrp'>
              <ul>
                {authMenu.filter((nv) => nv.access.includes(getAuthRole())).map((element, key) => (
                  <li key={key}>
                    <Divider />
                    <Link className={pathname === element.link ? 'active-nav' : null} to={`${element.link}`} style={{ width: '100%' }} >
                      <Button className="nav-link-btn" key={element.link} sx={{ color: '#fff' }}>
                        <span className='menu-icon'>{element.icon}</span>
                        <ListItemText primary={translateThis(element.title)} />
                      </Button>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Button className='closeBackBtn' onClick={handleDrawerClose} />
    </>
  );
}