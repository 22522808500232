
/**
* @author Geethananth M
 * Use this file to define app related constants
 */

/**
 * AppIcons for blog dummy data user roles
 */
import blog1 from "../assets/images/welcomeBG.png";
import blog2 from "../assets/images/propImg1.png";
import blog3 from "../assets/images/propImg2.png";
import blog4 from "../assets/images/propImg3.png";



export const blogDataArr = [
    {
        id: '1',
        title: "My First Blog Post",
        subTitle: "A brief overview",
        shortDescription: "This is a short summary",
        description: "This is the full content of my blog post",
        // image: "https://example.com/image.jpg",
        image: blog1,
        status: "published",
        isDeleted: false,
        categories: ["6734aa27c200134b4c77661b"]
    },
    {
        id: '2',
        title: "My First Blog Post",
        subTitle: "A brief overview",
        shortDescription: "This is a short summary",
        description: "This is the full content of my blog post",
        // image: "https://example.com/image.jpg",
        image: blog2,
        status: "draft",
        isDeleted: false,
        categories: ["6734aa27c200134b4c77661b"]
    },
    {
        id: '3',
        title: "My First Blog Post",
        subTitle: "A brief overview",
        shortDescription: "This is a short summary",
        description: "This is the full content of my blog post",
        // image: "https://example.com/image.jpg",
        image: blog3,
        status: "published",
        isDeleted: false,
        categories: ["6734aa27c200134b4c77661b"]
    },
    {
        id: '4',
        title: "My First Blog Post",
        subTitle: "A brief overview",
        shortDescription: "This is a short summary",
        description: "This is the full content of my blog post",
        // image: "https://example.com/image.jpg",
        image: blog4,
        status: "published",
        isDeleted: false,
        categories: ["6734aa27c200134b4c77661b"]
    },
]


const CONSTANTS = {
    EMAIL_REGEX: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    VALID_PASSWORD_RULE: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
}
export default CONSTANTS;
export const DATE_ONLY_FORMAT = "DD";
export const MONTH_TEXT_FORMAT = "MMM";
export const MONTH_YEAR_TEXT_FORMAT = "MMMM, YYYY";
export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_EXTEND_FORMAT = "DD MMMM YYYY";
export const TIME_FORMAT = 'HH:mm';
export const TIME_12HR_FORMAT = 'hh:mm a';
export const TIME_12HR_EXTENDED_FORMAT = 'hh:mm:ss a';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a';
export const DATE_READABLE_FORMAT = 'DD MMMM YYYY';
export const DATE_TIME_READABLE_FORMAT = 'dddd, DD MMMM YYYY';
export const DATE_TIME_MESSAGE_RECEIVED_FORMAT = 'ddd, MMM DD, hh:mm a';
export const DATE_TIME_EXTENDED_FORMAT = 'MM/DD/YYYY hh:mm:ss a';
export const USER_ROLES = {
    ADMIN: 'admin',
    EXTERNAL_AGENT: 'agent',
    USER: 'user',
    MASTER_ADMIN: 'master_admin',
}