import React from 'react';
 import {Editor,  EditorState,  convertFromRaw} from 'draft-js';
import { PropTypes } from 'prop-types';
import './richText.scss';

 const RichTextViewer = (props) => {
    const itemText= EditorState.createWithContent(convertFromRaw(JSON.parse(props.richText)));
   return (
      <div className="readonly-editor">
        <Editor editorState={itemText} readOnly /> 
      </div>
   );
 }
RichTextViewer.propTypes = {
  richText: PropTypes.string
}
 export default RichTextViewer;