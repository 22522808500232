import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// @mui
// import { Button, IconButton } from '@mui/material';
import { Container, Typography, Stack, Button, Grid, TextField, IconButton } from '@mui/material';



// components
import { showSnackbar } from '../../components/Notification/Snackbar';
import RichTextEditor from '../../components/rich-text-editor';
// Helpers
import SVGIcons from '../../assets/images/icons/svgIcons';

import FileUploader from '../../components/AwsS3ImageUploader/file.uploader';
import './index.scss';
import ApiCaller from '../../services/api/general';

import RichTextViewer from '../../components/rich-text-editor/TextViewer';


import BackArrow from '../../assets/images/BackArrow.svg';
import edit from '../../assets/images/edit.svg';
// ........................................................................




const AddEditNews = () => {
    const { id = '', page = 'add' } = useParams();
    const navigate = useNavigate();
    const apiCaller = new ApiCaller('news');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [isEditEnabled, setIsEditEnabled] = useState(id === undefined || ['add', 'edit'].includes(page));
    const [formData, setFormData] = useState({
        image: {
            fileKey: '',
            tempURL: ''
        },
        title: '',
        subTitle: '',
        shortDescription: '',
        author: '',
        status: 'draft',
        description: ''
    });

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = { ...formData };
        setFormData({ ...formDataAs, ...{ [name]: value } });
        return true;
    }


    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (id) {
            getData()
        }
        return () => {
            setFormData({})
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(id)
            .then(({ data }) => {
                if (data) {
                    setFormData(data)
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }


    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.image?.fileKey && formData.title && formData.subTitle && formData.description) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = (status, refreshData = false) => {
        setIsTouched(true);
        if (isValidForm()) {
            const postData = {
                title: formData.title,
                subTitle: formData.subTitle,
                shortDescription: formData.shortDescription,
                author: formData.author,
                status,
                description: formData.description,
                image: formData.image.fileKey
            }
            // Submitting data to backend for registration
            setIsSubmitting(true);
            let message = "News created successfully";
            let action = saveNews;
            if (id) {
                action = updateNews;
                message = 'News updated successfully!';
            }
            action(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(message);
                    navigate(-1)
                } else {
                    showSnackbar().failure(data.message)
                }
            }).finally(() => {
                setIsSubmitting(false);
                if (id && refreshData) {
                    getData()
                }
            });
        }
    };

    /**
     * Saves a news
     */
    const saveNews = (postData) => apiCaller.postData(postData);

    /**
     * Updates a news using its id
     */
    const updateNews = (postData) => apiCaller.customPatchData(id, postData);



    return (
        <>
            <Container style={{ paddingTop: '30px' }}>
                {!isLoading ?
                    <div className='list-main-wrp addEditNews f-wrp' style={{ paddingTop: '10px' }}>
                        <div className="breadcrumb f-wrp">
                            <Link to={"/blog"}><img src={BackArrow} alt="back-arrow" />back</Link>
                        </div>
                        <div className='title-head f-wrp'>
                            <div className='title-sec'>
                                <Typography variant="h4" gutterBottom>New Blog</Typography>
                            </div>
                            {id ? <div className='button-sec'>
                                <Button onClick={() => setIsEditEnabled((prv) => !prv)} className={`cancel`} >{isEditEnabled ? 'Cancel' : 'Edit'}</Button> &nbsp; 
                                <Button onClick={() => onSubmit(formData.status === 'DRAFT' ? 'PUBLISHED' : 'DRAFT', true)} className={`fill-btn ${formData?.status}-btn`} >{formData?.status === 'DRAFT' ? 'Publish' : 'Mark as Draft'}</Button>
                            </div> : null}

                        </div>
                        <div className='singleImageUpload f-wrp'>
                            <>
                                <div className='image-uploader'>
                                    <h3>Images</h3>
                                    {isEditEnabled ? <FileUploader
                                        acceptOnly='image/*'
                                        // uploadIcon={uploadBtn2}
                                        uploadCaption={''}
                                        onUploadEnd={(file) => setFormValue('image', file)}
                                    /> : null}
                                    {formData?.image?.tempURL ? <span className='uploaded-img'>
                                        {isEditEnabled ? <IconButton children={<SVGIcons.CloseCrossIcon />} onClick={() => setFormValue('image', { fileKey: '', tempURL: '' })} /> : null}
                                        <img style={{ width: '200px', height: '200px' }} src={formData.image.tempURL} alt={"bulletin"} />
                                    </span> : null}
                                </div>
                            </>
                        </div>

                        <div className='info-sec-wrp f-wrp'>
                            <div className='general-info-sec f-wrp'>
                                <div className='info-head f-wrp'>
                                    <span>General info:</span>
                                    <Button className='edit-btn'><img src={edit} alt="edit" /></Button>
                                </div>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Stack className='login-form profile-form' spacing={3}>
                                            <TextField
                                                focused
                                                disabled={!isEditEnabled}
                                                id="bulletin_create_form_question_title"
                                                name="title"
                                                label="Title:"
                                                placeholder='Type here'
                                                type="text"
                                                helperText={!id && isTouched && !formData.title ? 'Title is required' : ''}
                                                error={isTouched && !formData.title}
                                                value={formData?.title}
                                                onChange={(e) => setFormValue('title', e.target.value)}
                                            />

                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Stack className='login-form profile-form' spacing={3}>
                                            <TextField
                                                focused
                                                disabled={!isEditEnabled}
                                                id="bulletin_create_form_question_subtitle"
                                                name="subTitle"
                                                label="Subtitle:"
                                                placeholder='Type here'
                                                type="text"
                                                helperText={!id && isTouched && !formData.subTitle ? 'Subtitle is required' : ''}
                                                error={isTouched && !formData.subTitle}
                                                value={formData?.subTitle}
                                                onChange={(e) => setFormValue('subTitle', e.target.value)}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className='general-info-sec f-wrp'>
                                <div className='info-head f-wrp'>
                                    <span>Short description:</span>
                                </div>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Stack className='login-form profile-form' spacing={3}>
                                            <TextField
                                                focused
                                                disabled={!isEditEnabled}
                                                id="bulletin_create_form_question_shortDescription"
                                                name="shortDescription"
                                                label="shortDescription:"
                                                placeholder='Type here'
                                                type="text"
                                                helperText={!id && isTouched && !formData.shortDescription ? 'Short Description is required' : ''}
                                                error={isTouched && !formData.shortDescription}
                                                value={formData?.shortDescription}
                                                onChange={(e) => setFormValue('shortDescription', e.target.value)}
                                                multiline
                                                rows={4}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className='general-info-sec f-wrp'>
                                <div className='info-head f-wrp'>
                                    <span>Description:</span>
                                </div>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Stack className='login-form profile-form' spacing={3}>
                                            {isEditEnabled ? <RichTextEditor defaultValue={formData.description} placeholder={'Type here'} onChange={(value) => setFormValue('description', JSON.stringify(value))} /> : <>
                                                {formData?.description ? <RichTextViewer richText={formData?.description} /> : null}
                                            </>}
                                            {isTouched && !formData.description ? <span style={{ color: 'red' }}>Description is required</span> : null}

                                        </Stack>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className='general-info-sec f-wrp'>
                                <div className='info-head f-wrp'>
                                    <span>Author:</span>
                                </div>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Stack className='login-form profile-form' spacing={3}>
                                            <TextField
                                                focused
                                                disabled={!isEditEnabled}
                                                id="bulletin_create_form_question_author"
                                                name="author"
                                                // label="Author:"
                                                placeholder='Type here'
                                                type="text"
                                                helperText={!id && isTouched && !formData.author ? 'author name is required' : ''}
                                                error={isTouched && !formData.author}
                                                value={formData?.author}
                                                onChange={(e) => setFormValue('author', e.target.value)}
                                            />

                                        </Stack>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                        <div className='button-sec f-wrp'>
                            <Button onClick={() => navigate('/blog')} className='cancel'>Cancel</Button> &nbsp;
                            <Button onClick={() => onSubmit('draft')} className='fill-btn'>Save as draft</Button> &nbsp;
                            <Button onClick={() => onSubmit('published')} className='fill-btn'>Publish</Button>
                        </div>
                    </div> : <span className='page-loader'>{SVGIcons.LoaderIcon()}</span>}

            </Container>
        </>
    );
}

AddEditNews.propTypes = {}

export default AddEditNews;