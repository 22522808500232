import instance from './axios';

const axiosInstance = instance;

/**
 * Post a property
 * @param {Object} postData email and password
 */
export const postProperty = async (postData) => {
    try {
        const result = await axiosInstance.post('/properties', postData);
        const {data} = result;        
        return {success: true, data, message:'Property added successfully!.' };
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}

/**
 * Update a property
 * @param {string} propertyId
 */
export const deleteProperty = async (propertyId) => {
    try {
        const result = await axiosInstance.delete(`/properties/${propertyId}`);
        const {data} = result;        
        return data;
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}

/**
 * Update a property
 * @param {string} propertyId
 * @param {Object} postData the form data
 */
export const updateProperty = async (propertyId, postData) => {
    try {
        const result = await axiosInstance.patch(`/properties/${propertyId}`, postData);
        const {data} = result;        
        return data;
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false , message: errorMessage});
    }
}

/**
 * Gets the list of properties
 * @returns Object
 */
export const getProperties = async (requestFilter = {}) => {
    try {
        const filter = {...requestFilter, ...{page: 1, limit: 1000 }}
        const queryParams = new URLSearchParams(filter).toString();
        const result = await axiosInstance.get(`/properties?${queryParams}`);
        const data = result.data?.results || [];        
        return data;
    } catch (e) {
        return [];
    }
}


/**
 * Gets the property by id
* @param {string} propertyId;
 * @returns Object
 */
export const getProperty = async (propertyId) => {
    try {
        const result = await axiosInstance.get(`/properties/${propertyId}`);
        const {data=null} = result;        
        return data;
    } catch (e) {
        return null;
    }
}