import React, { useState } from 'react';
import propTypes from 'prop-types';

import uploadBtn2 from '../../assets/images/uploadBtn2.svg';
import { uploadImage } from '../../services/api/aws';

import loader from '../../assets/images/loader.svg';
import deleteIcon from '../../assets/images/Delete.svg';



/**
 * 
 * @param {Object} props 
 * @returns JSX Element
 */

const AwsS3ImageUploader = (props) => {
    const {
        uploaderKey = 'uploader-file',
        onUploadStart = () => null,
        onUploadEnd = () => null,
        onRemoveFile = () => null,
        defaultImages = []
    } = props;
    const [propertyImages, setPropertyImages] = useState(defaultImages);
    const [isLoading, setIsLoading] = useState(false);


    /**
       * Handles the file upload
       * @param {any} e 
       */
    const handleUpload = async (e) => {
        const { files } = e.target;
        setIsLoading(true);
        onUploadStart();
        const images = [];
        // Call the uploader
        // eslint-disable-next-line
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            // eslint-disable-next-line
            const img = await uploadToServer(file);
            images.push({fileKey: img.key, tempURL: img.signedUrl})
        }
        onUploadEnd(propertyImages.concat(images.map((img) => ({ fileKey: img.fileKey, tempURL: img.tempURL }))));
        const tempImages = [...propertyImages];
        tempImages.concat(images);
        setPropertyImages([...propertyImages, ...images]);
        e.target.value = '';
        setIsLoading(false);
        return null;
    }

    const uploadToServer = async (file) => {
        if (file) {
            return uploadImage(file, (result) => ({fileKey: result.key, tempURL: result.signedUrl}))
        }
        return null;
    }

    /**
     * Removes a file by its key
     * @param {string} fileKey 
     */
    const removeFile = (fileKey) => {
        const tempImages = [...propertyImages.filter((f) => f.fileKey !== fileKey)];
        setPropertyImages([...tempImages]);
        onRemoveFile(tempImages);
    }

    return (
        <div key={uploaderKey}>
            <div className='img-upload-wrp f-wrp'>
                <div className='upload-img single-upload-btn'>
                    {isLoading ? <span className='sm-loader'><img src={loader} alt='loading' /></span> : null}
                    <label htmlFor={uploaderKey}><img src={uploadBtn2} alt="fileUpload" />
                        <input
                            accept="image/*"
                            multiple
                            className="property-image"
                            id={uploaderKey}
                            disabled={isLoading}
                            type="file"
                            onChange={handleUpload}
                        />
                    </label>
                </div>
                {propertyImages.map((image, key) => <div className='each-img-blk' key={`${uploaderKey}-image-${key}`}>
                    <span style={{ position: 'relative' }}>
                        <button className='delete-btn' type='button' onClick={() => removeFile(image.fileKey)} style={{ cursor: 'pointer' }}><img src={deleteIcon} alt='delete' /></button>
                        <img style={{ width: '155px', height: '100px' }} src={image.tempURL} alt={image.fileKey} />
                    </span>
                </div>)}
            </div>
        </div>
    )
};

AwsS3ImageUploader.propTypes = {
    uploaderKey: propTypes.string,
    onUploadStart: propTypes.func,
    onUploadEnd: propTypes.func,
    onRemoveFile: propTypes.func,
    defaultImages: propTypes.arrayOf(propTypes.shape({
        tempURL: propTypes.string,
        fileKey: propTypes.string,
    }))
};
export default AwsS3ImageUploader;

