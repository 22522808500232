export const PropertyModel = {
    id: '',
    title: '',
    isDiscountedPrice: false,
    isNewListing: false,
    subTitle: '',
    description: '',
    price: '',
    address: '',
    referent: '',
    features: '',
    currency: '',
    plot: '',
    bua: '',
    location: '',
    latitude: '',
    longitude: '',
    images: [],
    agentName: '',
    operationType: '',
    completionStatus: '',
    areaMeasureUnit: '',
    area: '',
    noOfBedrooms: '',
    noOfBathrooms: '',
    category: '',
    subcategory: '',
    barbequeArea: false,
    lawnOrGarden: false,
    cafeteria: false,
    daycare: false,
    kidsPlayArea: false,
    sundeck: false,
    laundryRoom: false,
    laundryFacility: false,
    sharedKitchen: false,
    medicalCenter: false,
    sauna: false,
    jacuzzi: false,
    disabledFriendly: false,
    gym: false,
    healthClub: false,
    swimmingPool: false,
    steamRoom: false,
    balcony: false,
    terrace: false,
    lobby: false,
    serviceElevators: false,
    prayerRoom: false,
    reception: false,
    atmFacility: false,
    concierge24h: false,
    maidsRoom: false,
    bussinessCenter: false,
    conferenceRoom: false,
    securityStaff: false,
    cctvSecurity: false,
    doubleGlazedWindows: false,
    centralyAirConditioned: false,
    centalHeating: false,
    electricityBackup: false,
    studyRoom: false,
    storageAreas: false,
    broadbandInternet: false,
    sateliteCableTV: false,
    intercom: false,
    cleaningservices: false,
    maintenanceStaff: false,
    wasteDisposal: false,
}

export const PropertyCategories = [
    {
        id: 'residential',
        value: 'residential',
        label: 'Residential',
        subcategories: [
            {
                id: 'apartment',
                value: 'apartment',
                label: 'Apartment',
            },
            {
                id: 'residentialFloor',
                value: 'residentialFloor',
                label: 'Residential Floor',
            },
            {
                id: 'residentialPlot',
                value: 'residentialPlot',
                label: 'Residential Plot',
            },
            {
                id: 'townhouse',
                value: 'townhouse',
                label: 'Townhouse',
            },
            {
                id: 'residentialBuilding',
                value: 'residentialBuilding',
                label: 'Residential Building',
            },
            {
                id: 'penthouse',
                value: 'penthouse',
                label: 'Penthouse',
            },
            {
                id: 'villaCompound',
                value: 'villaCompound',
                label: 'Villa Compound',
            },
            {
                id: 'hotelApartment',
                value: 'hotelApartment',
                label: 'Hotel Apartment',
            },
        ]

    },
    {
        id: 'commercial',
        value: 'commercial',
        label: 'Commercial',
        subcategories: [
            {
                id: 'shop',
                value: 'shop',
                label: 'Shop',
            },
            {
                id: 'warehouse',
                value: 'warehouse',
                label: 'Warehouse',
            },
            {
                id: 'factory',
                value: 'factory',
                label: 'Factory',
            },
            {
                id: 'labourCamp',
                value: 'labourCamp',
                label: 'Labour Camp',
            },
            {
                id: 'commercialBuilding',
                value: 'commercialBuilding',
                label: 'Commercial Building',
            },
            {
                id: 'office',
                value: 'office',
                label: 'Office',
            },
            {
                id: 'commercialFloor',
                value: 'commercialFloor',
                label: 'Commercial Floor',
            },
            {
                id: 'commercialPlot',
                value: 'commercialPlot',
                label: 'Commercial Plot',
            },
            {
                id: 'bulkUnit',
                value: 'bulkUnit',
                label: 'Bulk Unit',
            },
            {
                id: 'industrialLand',
                value: 'industrialLand',
                label: 'Industrial Land',
            },
            {
                id: 'mixedUseLand',
                value: 'mixedUseLand',
                label: 'Mixed Use Land',
            },
            {
                id: 'showroom',
                value: 'showroom',
                label: 'Showroom',
            },
            {
                id: 'commercialVilla',
                value: 'commercialVilla',
                label: 'Commercial Villa',
            },
            {
                id: 'otherCommercial',
                value: 'otherCommercial',
                label: 'Other Commercial',
            },
        ]
    }
]