import React from 'react';
// import { translateThis } from '../../helpers/language.helper';
import SVGIcons from '../../assets/images/icons/svgIcons';
import './pagination.scss';

const Pagination = (props) => {  
  const { limit, page, totalCount, onPageChange } = props;
  const totalPages = Math.ceil(totalCount / limit);
  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;

  const handlePreviousPage = (delta) => {
    if (page - delta >= 1) {
      onPageChange(limit, page - delta);
    }
  };

  const handleNextPage = (delta) => {
    if (page + delta <= totalPages) {
      onPageChange(limit, page + delta);
    }
  };

  return (
    <div className="pagination">
      <p>{'Rows per page:'}</p>
      <select value={limit} onChange={(e)=>onPageChange(e.target.value, 1)}>
        <option value={10}>{'10'}</option>
        <option value={25}>{'25'}</option>
        <option value={50}>{'50'}</option>
        <option value={100}>{'100'}</option>
      </select>

      <span className="pagination-text">
        {page} - {totalPages} {'of'} {totalPages} Page{totalPages > 1 ? 's' : ''} 
      </span>
      
      {page > 2 && (
        <button type='button'
          className="pagination-button"
          onClick={() => handlePreviousPage(2)}
        >
          <b>{page - 2}</b>
        </button>
      )}
      {page > 1 && (
        <button type='button'
          className="pagination-button"
          onClick={() => handlePreviousPage(1)}
        >
          <b>{page - 1}</b>
        </button>
      )}
      <button type='button'
        className="pagination-button"
        disabled={isFirstPage}
        onClick={() => handlePreviousPage(1)}
      >
        <SVGIcons.ChevronLeftIcon />
      </button>
      <span className="pagination-text-box">
        {page}
      </span>
      <button type='button'
        className="pagination-button"
        disabled={isLastPage}
        onClick={() => handleNextPage(1)}
      >
        <SVGIcons.ChevronRightIcon />
      </button>
      {page < totalPages - 1 && (
        <button type='button'
          className="pagination-button"
          onClick={() => handleNextPage(1)}
        >
          <b>{page + 1}</b>
        </button>
      )}
      {page < totalPages - 2 && (
        <button type='button'
          className="pagination-button"
          onClick={() => handleNextPage(2)}
        >
          <b>{page + 2}</b>
        </button>
      )}
    </div>
  );
};

export default Pagination;