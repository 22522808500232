import instance from './axios';

class ApiCaller {

    axiosInstance = instance;

    constructor(route) {
        this.route = route;
    }

    /**
    * Post a property
    * @param {Object} postData email and password
    */
    postData = async (postData) => {
        try {
            const result = await this.axiosInstance.post(`/${this.route}`, postData);
            const { data, status } = result;
            return ({ success: [201, 200].includes(status), message: "Resource created successfully", data });
        } catch (e) {
            const errorMessage = e.response?.data?.message;
            return ({ success: false, message: errorMessage });
        }
    }

    /**
    * custom post
    * @param {Object} postData email and password
    */
    customPostData = async (route = '', postData) => {
        try {
            const result = await this.axiosInstance.post(`/${this.route}${route}`, postData);
            const { data, status } = result;
            return ({ success: [201, 200].includes(status), message: "Resource created successfully", data });
        } catch (e) {
            const errorMessage = e.response?.data?.message;
            return ({ success: false, message: errorMessage });
        }
    }

    /**
     * Update a property
     * @param {string} id
     */
    deleteData = async (id) => {
        try {
            const result = await this.axiosInstance.delete(`/${this.route}/${id}`);
            return { success: result.status === 200, message: 'Resource deleted successfully!' };
        } catch (e) {
            const errorMessage = e.response?.data?.message;
            return ({ success: false, message: errorMessage });
        }
    }

    /**
     * Update a data
     * @param {string} id
     * @param {Object} postData the form data
     */
    updateData = async (id, postData) => {
        try {
            const result = await this.axiosInstance.patch(`/${this.route}`, postData);
            const { statusText } = result;
            return ({ success: true, message: statusText });
        } catch (e) {
            const errorMessage = e.response?.data?.message;
            return ({ success: false, message: errorMessage });
        }
    }

    /**
     * Update a data
     * @param {string} id
     * @param {Object} postData the form data
     */
    putData = async (id, postData) => {
        try {
            const result = await this.axiosInstance.put(`/${this.route}`, postData);
            const { statusText } = result;
            return ({ success: true, message: statusText });
        } catch (e) {
            const errorMessage = e.response?.data?.message;
            return ({ success: false, message: errorMessage });
        }
    }

    /**
     * Custom Update a data
     * @param {string} id
     * @param {Object} postData the form data
     */
    customPutData = async (id, postData) => {
        try {
            const result = await this.axiosInstance.put(`/${this.route}/${id}`, postData);
            const { statusText } = result;
            return ({ success: true, message: statusText });
        } catch (e) {
            const errorMessage = e.response?.data?.message;
            return ({ success: false, message: errorMessage });
        }
    }

    /**
    * Custom Patches/Update a data
    * @param {string} id
    * @param {Object} patchData the form data
    */
    customPatchData = async (id, patchData) => {
        try {
            const result = await this.axiosInstance.patch(`/${this.route}/${id}`, patchData);
            const { statusText } = result;
            return ({ success: true, message: statusText });
        } catch (e) {
            const errorMessage = e.response?.data?.message;
            return ({ success: false, message: errorMessage });
        }
    }

    /**
     * Gets the list of data
     * @param {object} filter the query params &filter=value
     * @returns Object
     */
    getList = async (filter = {}) => {
        const { limit = 10000, page = 1, query = '' } = filter;
        const formattedQuery = query ? `?${query}` : '';
        const queryParams = `&limit=${limit}&page=${page}&${formattedQuery}`
        try {            
            const result = await this.axiosInstance.get(`/${this.route}${queryParams}`);
            const { data = {} } = result;
            return data;
        } catch (e) {
            const errorMessage = e.response?.data?.message;
            return ({ success: false, message: errorMessage });
        }
    }

    /**
     * Gets the list of data
     * @param {object} filter the query params &filter=value
     * @returns Object
     */
    getCustomList = async (url = '', filter = {}) => {
        const { limit = 10000, page = 1, query = '' } = filter;
        const queryParams = `limit=${limit}&page=${page}&${query}`
        try {
            // const result = await this.axiosInstance.get(`/${this.route}`);
            const result = await this.axiosInstance.get(`/${this.route}${url}?${queryParams}`);
            const data = result.data || { results: [] };
            return data;
        } catch (e) {
            return { results: [] };
        }
    }


    /**
     * Gets the data by id
    * @param {string} id;
     * @returns Object
     */
    getData = async (id) => {
        try {
            const result = await this.axiosInstance.get(`/${this.route}/${id}`);
            const { data = null } = result;
            return data;
        } catch (e) {
            return null;
        }
    }

}
export default ApiCaller;