import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import { TurnedIn } from '@mui/icons-material';
import { Container, Typography, Stack, Button, Grid, InputAdornment, TextField, IconButton, Tooltip, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { PropertyCategories } from '../../models/property.model';

import BackArrow from '../../assets/images/BackArrow.svg';
import ArrowsOut from '../../assets/images/ArrowsOut.svg';
import focus from '../../assets/images/focus.svg';

import { getProperty, updateProperty } from '../../services/api/property';
import RichTextViewer from '../../components/rich-text-editor/TextViewer';
import { showSnackbar } from '../../components/Notification/Snackbar';
import BasicDropdown from '../../components/dropdowns/basic.dropdown';
import SVGIcons from '../../assets/images/icons/svgIcons';
import AppLayout from '../../layouts/app/AppLayout';
import HeaderRightUserInfo from '../../components/headerRightUserInfo';
import MapSection from '../../components/MapSection';

// ----------------------------------------------------------------------

export default function ViewProperty() {
  const params = useParams();
  const navigator = useNavigate();
  const propertyId = params?.id || '';
  const [propertyItem, setPropertyItem] = useState(null);
  const [location, setLocation] = useState({
    latitude: '',
    longitude: '',
    location: ''
  });

  useEffect(() => {
    if (propertyId) {
      getPropertyAs();
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Get the property by its id;
   */
  const getPropertyAs = () => {
    getProperty(propertyId).then((property) => {
      setPropertyItem(property);
      setLocation({ 
        location: property?.location,
        latitude: property?.latitude || 0,
        longitude: property?.longitude || 0,
       });
    })

  }

  const setThumbnail = (img) => {
    const postData = {
      thumbnails: [img.fileKey]
    }
    updateProperty(propertyId, postData).then(() => {
      getPropertyAs();

    }).finally(() => showSnackbar().success('Thumbnail updated successfully'))
  }

  const pageName = "Listing";

  return (
    <AppLayout
      pageName={pageName}
    >
      <div className="main-content-wrapper f-wrp">
        <div className='panel-main-header f-wrp'>
          <h1>{'All Listings'}</h1>
          <HeaderRightUserInfo />
        </div>
        <div className="main-container-wrp">

          <Container style={{ paddingTop: '30px' }}>

            <div className='list-main-wrp f-wrp' style={{ paddingTop: '10px' }}>
              <div className="breadcrumb f-wrp">
                <Link to={"/dashboard/app"}><img src={BackArrow} alt="back-arrow" />back</Link>
              </div>
              <div className='title-head f-wrp'>
                <div className='title-sec'>
                  <Typography variant="h4" gutterBottom>View listing</Typography>
                </div>
                <div className='button-sec'>
                  <Button onClick={() => navigator(`/dashboard/edit/${propertyItem.id}`)} className='fill-btn'>Edit</Button>
                </div>
              </div>

              <div className='img-upload-wrp f-wrp'>
                <h3>Images</h3>
                {propertyItem && propertyItem.images.map((img, k) => <div key={k} className='each-img-blk'>
                  <span style={{ position: 'relative' }}>
                    <Tooltip
                      title={'Thumbnail'}
                    >
                      <IconButton
                        className='delete-btn'
                        aria-label="make as thumbnail"
                        onClick={() => setThumbnail(img)}
                        children={(propertyItem?.thumbnails && propertyItem.thumbnails.filter((i) => i && i.tempURL).filter((thumb) => thumb.fileKey === img.fileKey).length > 0) ? <TurnedIn /> : <TurnedInNotIcon />} />
                    </Tooltip>
                    <img style={{ width: '155px', height: '100px' }} src={img.tempURL} alt={img.fileKey} />
                  </span>

                </div>)}


              </div>
              {(propertyItem) && <div className='info-sec-wrp f-wrp'>
                <div className='general-info-sec f-wrp'>
                  <div className='info-head f-wrp'>
                    <span>General info:</span>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField type='text'
                          defaultValue={propertyItem.title}
                          name="title"
                          label="Title:"
                          disabled
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="Subtitle"
                          label="Subtitle:"
                          name='subTitle'
                          defaultValue={propertyItem.subTitle}
                          disabled
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="price"
                          label="price"
                          name='price'
                          defaultValue={propertyItem.price}
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                AED
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="Plotsft"
                          label="Plot sft:"
                          name='plot'
                          defaultValue={propertyItem.plot}
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={ArrowsOut} alt="back-arrow" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="BUAsft"
                          label="BUA sft:"
                          name='bua'
                          defaultValue={propertyItem.bua}
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={focus} alt="back-arrow" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Grid>





                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <BasicDropdown
                          key={'operationType'}
                          label="Choose operation"
                          id='selectedOperation'
                          isDisabled
                          options={[
                            {
                              'label': 'Sell',
                              "id": 'sell',
                              "value": 'sell',
                            },
                            {
                              'label': 'Rent',
                              "id": 'rent',
                              "value": 'rent',
                            },
                            {
                              'label': 'Other',
                              "id": 'other',
                              "value": 'other',
                            },

                          ]}
                          // onChange={(value) => setValue('operationType', value)}
                          value={propertyItem?.operationType}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <BasicDropdown
                          key={'completionStatus'}
                          label="Completion status"
                          id='completionStatus'
                          isDisabled
                          options={[
                            {
                              'label': 'Completed',
                              "id": 'completed',
                              "value": 'completed',
                            },
                            {
                              'label': 'In progress',
                              "id": 'inprogress',
                              "value": 'inprogress',
                            },
                            {
                              'label': 'Proposed',
                              "id": 'proposed',
                              "value": 'proposed',
                            },

                          ]}
                          // onChange={(value) => setValue('completionStatus', value)}
                          value={propertyItem?.completionStatus}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <BasicDropdown
                          key={'category'}
                          label="Property Type"
                          id='propertyType'
                          options={PropertyCategories}
                          isDisabled
                          // onChange={(value) => setValue('category', value)}
                          value={propertyItem?.category}
                        />
                      </Stack>
                    </Grid>
                    {propertyItem?.category !== '' ? <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <BasicDropdown
                          key={'subcategory'}
                          label="Property sub type"
                          id='propertyType'
                          isDisabled
                          options={PropertyCategories.find((c) => c.id === propertyItem.category)?.subcategories}
                          // onChange={(value) => setValue('subcategory', value)}
                          value={propertyItem?.subcategory}
                        />
                      </Stack>
                    </Grid> : null}

                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <BasicDropdown
                          key={'areaUnit'}
                          label="Area unit"
                          id='areaUnit'
                          isDisabled
                          options={[
                            {
                              'label': 'sqm',
                              "id": 'sqm',
                              "value": 'sqm',
                            },
                            {
                              'label': 'Sqf',
                              "id": 'sqf',
                              "value": 'sqf',
                            },

                          ]}
                          // onChange={(value) => setValue('areaMeasureUnit', value)}
                          value={propertyItem?.areaMeasureUnit}
                        />
                      </Stack>
                    </Grid>

                    {(propertyItem?.areaMeasureUnit !== '') ? <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="AreaInSqmOrSqf"
                          label="Area: (in sqm or sqf)"
                          name='area'
                          defaultValue={propertyItem.area}
                          disabled
                          // onChange={({ target: { name, value } }) => setValue(name, value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                sqm/sqf
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Grid> : null}
                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="noOfBedrooms"
                          label="Number Of Bedrooms"
                          name='noOfBedrooms'
                          type='number'
                          min='0'
                          defaultValue={propertyItem.noOfBedrooms}
                          disabled
                          // onChange={({ target: { name, value } }) => setValue(name, value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SVGIcons.BedIcon style={{ width: '18px', height: '18px' }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          id="noOfBathrooms"
                          label="Number Of Bathrooms"
                          name='noOfBathrooms'
                          type='number'
                          min='0'
                          defaultValue={propertyItem.noOfBathrooms}
                          disabled
                          // onChange={({ target: { name, value } }) => setValue(name, value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SVGIcons.BathIcon style={{ width: '18px', height: '18px' }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField
                          type='text'
                          name="agentName"
                          title="Agent name"
                          defaultValue={propertyItem.agentName}
                          label="Agent name:"
                          disabled
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.isDiscountedPrice} />} label="Distress Property" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.isNewListing} />} label="Exclusive" />
                        </FormGroup>
                      </Stack>
                    </Grid>

                    <Grid style={{ display: 'none' }} item xs={12} sm={6} md={6}>
                      <Stack className='login-form profile-form' spacing={3}>
                        {/* <TextField
                      type='text'
                      name="Other"
                      label="Other:"
                      defaultValue="&nbsp;" /> */}
                      </Stack>
                    </Grid>
                  </Grid>
                </div>
                <div className='general-info-sec f-wrp'>
                  <div className='info-head f-wrp'>
                    <span>Description:</span>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3}>
                        {/* <TextField
                      id="Description"
                      label="Description:"
                      name='description'
                      disabled
                      multiline
                      rows={4}
                      defaultValue={propertyItem.description}
                    /> */}
                        <RichTextViewer richText={propertyItem.description} />
                      </Stack>
                    </Grid>
                  </Grid>
                </div>
                <div className='general-info-sec f-wrp'>
                  <div className='info-head f-wrp'>
                    <span>Features:</span>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <RichTextViewer richText={propertyItem.features} />
                      </Stack>
                    </Grid>
                  </Grid>
                </div>

                <div className='general-info-sec f-wrp'>
                  <div className='info-head f-wrp'>
                    <span>Amenities:</span>
                    {/* <Button className='edit-btn'><img src={edit} alt="edit" /></Button> */}
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Recreation and family</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.barbequeArea} />} label="Barbeque area" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.lawnOrGarden} />} label="Lawn or garden" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.cafeteria} />} label="Cafeteria" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.daycare} />} label="Daycare" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.kidsPlayArea} />} label="Kids play area" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.sundeck} />} label="Sundeck" />
                        </FormGroup>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Laundry and kitchen</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.laundryRoom} />} label="Laundry room" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.laundryFacility} />} label="Laundry facility" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.sharedKitchen} />} label="Shared kitchen" />

                        </FormGroup>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Facilities</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.medicalCenter} />} label="Medical center" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.sauna} />} label="Sauna" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.jacuzzi} />} label="Jacuzzi" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.disabledFriendly} />} label="Disabled friendly" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.gym} />} label="Gym" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.healthClub} />} label="Health club" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.swimmingPool} />} label="Swimming pool" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.steamRoom} />} label="Steam room" />

                        </FormGroup>
                      </Stack>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Building</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.balcony} />} label="Balcony" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.terrace} />} label="Terrace" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.lobby} />} label="Lobby" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.serviceElevators} />} label="Service elevators" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.prayerRoom} />} label="Prayer room" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.reception} />} label="Reception" />
                        </FormGroup>
                      </Stack>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Miscellaneous</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.atmFacility} />} label="ATM facility" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.concierge24h} />} label="24h concierge" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.maidsRoom} />} label="Maids room" />

                        </FormGroup>
                      </Stack>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Business and security</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.bussinessCenter} />} label="Bussiness center" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.conferenceRoom} />} label="Conference room" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.securityStaff} />} label="Security staff" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.cctvSecurity} />} label="CCTV security" />

                        </FormGroup>
                      </Stack>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Features</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.doubleGlazedWindows} />} label="Double glazed windows" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.centralyAirConditioned} />} label="Centraly air-conditioned " />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.centalHeating} />} label="Cental heating" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.electricityBackup} />} label="Electricity backup" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.studyRoom} />} label="Study room" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.storageAreas} />} label="Storage areas" />

                        </FormGroup>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Technology</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.broadbandInternet} />} label="Broadband internet" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.sateliteCableTV} />} label="Satelite/Cable TV" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.intercom} />} label="Intercom" />

                        </FormGroup>
                      </Stack>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3} style={{ paddingTop: '0' }}>
                        <div className='info-head f-wrp'><span>Cleaning and maintenance</span></div>
                        <FormGroup style={{ flexDirection: 'row' }}>
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.cleaningservices} />} label="Cleaning services" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.maintenanceStaff} />} label="Maintenance staff" />
                          <FormControlLabel disabled control={<Checkbox defaultChecked={propertyItem?.amenities?.wasteDisposal} />} label="Waste disposal" />

                        </FormGroup>
                      </Stack>
                    </Grid>

                  </Grid>
                </div>


                <div className='general-info-sec f-wrp'>
                  <div className='info-head f-wrp'>
                    <span>Location</span>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3}>
                        <TextField type='text'
                          name="location"
                          label="Area:"
                          disabled
                          defaultValue={propertyItem.location}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Stack className='login-form profile-form' spacing={3}>
                      <MapSection initialData={location} isViewOnly />
                      </Stack>
                    </Grid>
                  </Grid>
                </div>
              </div>}
              <div className='button-sec f-wrp'>
                <Button onClick={() => navigator('/dashboard/app')} className='cancel'>Cancel</Button> &nbsp;
                <Button onClick={() => navigator(`/dashboard/edit/${propertyItem.id}`)} className='fill-btn'>Edit</Button>
              </div>
            </div>
          </Container>

        </div>
      </div>
    </AppLayout >

  );
}
