/* eslint-disable arrow-body-style */
import React, { useEffect, useMemo, useState } from 'react';
import AppIcons from '../../assets/images/icons';
import SVGIcons from '../../assets/images/icons/svgIcons';
import ApiCaller from '../../services/api/general';

const HeaderRightUserInfo = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (!user) {
            getUserInfo()
        }
        

    }, []);

    const getUserInfo = () => {
        const apiCaller = new ApiCaller('auth');
        apiCaller.getData('me').then((data) => {
            if (data?.success) {
                setUser(data?.data);
            }
        }).catch((_e) => null)
            .finally(() => setIsLoading(false))
    }

    const UserSection = () => {
        const memoizedValue = useMemo(() => {
            // expensive computation or complex rendering
            return <>

                {(!isLoading && user?.firstName) ? <div className='header-basic-info user-profile-wrp'>
                    <div style={{ marginRight: '20px' }} className='language-box'>
                        <span>{SVGIcons.NavPlaceHolderIcon()}</span>
                    </div>
                    <span><img src={AppIcons.default} alt='name' /></span>
                    <div className='header-basic-con'>
                        <h4>{`${user?.firstName} ${user?.lastName}`}</h4>
                        <p>{user?.email}</p>
                    </div>

                </div> : null}
            </>;
        }, [isLoading, user]);

        return memoizedValue;
    };
    return (
        <>
        {UserSection()}
        </>
        
    );
}

export default HeaderRightUserInfo;
