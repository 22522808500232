import config from '../../config/config';
import instance from './axios';

const axiosInstance = instance;
const { tokenKey } = config;

// login via email and password
/**
 * Admin login
 * @param {Object} data email and password
 */
export const adminLogin = async (data) => {
    try {
        const result = await axiosInstance.post('/auth/admin-login', data);
        const authToken = result.data?.data?.tokens?.access?.token;
        if (authToken) {
            localStorage.setItem(tokenKey, authToken);
        }
        return ({ success: true, message: result.data?.message });
    } catch (e) {
        const errorMessage = e.response?.data?.message;
        return ({ success: false, message: errorMessage });
    }
}